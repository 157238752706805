import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF } from '@react-three/drei';
export function Model(props) {
  const groupRef = useRef(); // Create a ref for the group
  const { nodes, materials } = useGLTF('/Chest_Blender1_001.glb');

  // Update loop for rotation
  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.01; // Adjust rotation speed here
    }
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chestbase.geometry}
        material={materials.Material}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.chesttop.geometry}
          material={materials.Material}
          position={[-0.006, 0.732, 0.363]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload('/Chest_Blender1_001.glb');
